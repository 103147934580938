<template>
    <section id="sectionTwo" class="container mt-5">

        <h2 class="primary-color text-center text-md-start fw-bold">TECHNOLO<span tabindex="0" data-bs-toggle="modal" data-bs-target="#GiuliaModal">G</span>IES</h2>

        <div class="row justify-content-center" v-if="!screenOk">

            <!-- FRONT-END -->
            <div class="col-12 col-lg-6 mb-5">
                <span class="secondary-color fs-3 fs-lg-4 d-block text-center text-md-start">Front End</span>
                <div class="row">
                    <div class="col-6 col-md-4 col-lg-5 p-2" data-aos="fade-up" v-for="(el, id) in frontEndList" :key="id">
                        <div class="my-card cursor-pointer position-relative" @click="validScreenForModal()">

                            <div class="icon-card">
                                <i class="position-absolute top-50 start-50 translate-middle icon-skill" :class="el.classes" v-if="el.type=='icon'"></i>

                                <img :src="el.src" class="position-absolute top-50 start-50 translate-middle" :class="el.classes" v-else>
                            </div>

                            <div class="position-absolute bottom-0 start-0 w-100 info d-none d-lg-flex flex-column align-items-center" @click="showInfo" :class="{active: active}" @mouseleave="removeInfo" >
                                <i class="fas fa-sort-up arrow" v-if="!active"></i>
                                <h5 class="text-center mt-2">{{el.name}}</h5>
                                <i class="fas fa-sort-down arrow" v-if="active"></i>
                                <div class="info-paragraph">
                                    <div>
                                        <p class="mt-3">{{el.info}}</p>
                                    </div>
                                    <a :href="el.link" class="fs-4 cursor-pointer" target="_blank">READ MORE</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <!-- BACK-END -->
            <div class="col-12 col-lg-6">
                <span class="secondary-color fs-3 fs-lg-4 d-block text-center text-md-start">Back End</span>
                <div class="row">
                    <div class="col-6 col-md-4 col-lg-5 p-2" data-aos="fade-up" v-for="(el, id) in backEndList" :key="id">
                        <div class="my-card cursor-pointer position-relative" @click="validScreenForModal()">

                            <div class="icon-card">
                                <i class="position-absolute top-50 start-50 translate-middle icon-skill" :class="el.classes" v-if="el.type=='icon'"></i>

                                <img :src="el.src" class="position-absolute top-50 start-50 translate-middle" :class="el.classes" :alt="el.name" v-else>
                            </div>

                            <div class="position-absolute bottom-0 start-0 w-100 info d-none d-lg-flex flex-column align-items-center" @click="showInfo" :class="{active: active}" @mouseleave="removeInfo" >
                                <i class="fas fa-sort-up arrow" v-if="!active"></i>
                                <h5 class="text-center mt-2">{{el.name}}</h5>
                                <i class="fas fa-sort-down arrow" v-if="active"></i>
                                <div class="info-paragraph">
                                    <div>
                                        <p class="mt-3">{{el.info}}</p>
                                    </div>
                                    <a :href="el.link" class="fs-4 cursor-pointer" target="_blank">READ MORE</a>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>

        <!-- TECHNOLOFIES -->
        <div class="row justify-content-center" v-else>

            <!-- FRONT-END -->
            <div class="col-12 col-lg-6 mb-5">
                <span class="secondary-color fs-2 fs-lg-4 d-block text-center text-md-start">Front End</span>
                <div class="row">
                    <div class="col-6 col-md-4 col-lg-5 p-2" data-aos="fade-up" v-for="(el, id) in frontEndList" :key="id">
                        <div class="my-card cursor-pointer position-relative" tabindex="0" @click="validScreenForModal(); setObjModal(el)" data-bs-toggle="modal" data-bs-target="#detailModal">

                            <div class="icon-card">
                                <i class="position-absolute top-50 start-50 translate-middle icon-skill" :class="el.classes" v-if="el.type=='icon'"></i>

                                <img :src="el.src" class="position-absolute top-50 start-50 translate-middle" :class="el.classes" v-else>
                            </div>

                            <div class="position-absolute bottom-0 start-0 w-100 info d-none d-lg-flex flex-column align-items-center" @click="showInfo" :class="{active: active}" @mouseleave="removeInfo" >
                                <i class="fas fa-sort-up arrow" v-if="!active"></i>
                                <h5 class="text-center mt-2">{{el.name}}</h5>
                                <i class="fas fa-sort-down arrow" v-if="active"></i>
                                <div class="info-paragraph">
                                    <p class="mt-3">{{el.info}}</p>
                                    <a :href="el.link" class="fs-4 cursor-pointer" target="_blank">READ MORE</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <!-- BACK-END -->
            <div class="col-12 col-lg-6">
                <span class="secondary-color fs-2 fs-lg-4 d-block text-center text-md-start">Back End</span>
                <div class="row">
                    <div class="col-6 col-md-4 col-lg-5 p-2" data-aos="fade-up" v-for="(el, id) in backEndList" :key="id">
                        <div class="my-card cursor-pointer position-relative" tabindex="0" @click="validScreenForModal(); setObjModal(el)" data-bs-toggle="modal" data-bs-target="#detailModal">

                            <div class="icon-card">
                                <i class="position-absolute top-50 start-50 translate-middle icon-skill" :class="el.classes" v-if="el.type=='icon'"></i>

                                <img :src="el.src" class="position-absolute top-50 start-50 translate-middle" :class="el.classes" :alt="el.name" v-else>
                            </div>

                            <div class="position-absolute bottom-0 start-0 w-100 info d-none d-lg-flex flex-column align-items-center" @click="showInfo" :class="{active: active}" @mouseleave="removeInfo" >
                                <i class="fas fa-sort-up arrow" v-if="!active"></i>
                                <h5 class="text-center mt-2">{{el.name}}</h5>
                                <i class="fas fa-sort-down arrow" v-if="active"></i>
                                <div class="info-paragraph">
                                    <p class="mt-3">{{el.info}}</p>
                                    <a :href="el.link" class="fs-4 cursor-pointer" target="_blank">READ MORE</a>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>

        <!-- WORK TIMELINE -->
        <h2 class="primary-color text-center text-md-start fw-bold mt-5 mb-5">WORK-TIMELINE</h2>
        <div class="row pt-3" id="workTimeline">
            <ul>
                <li v-for="(el, id) in workTimelineList" :key="id">
                    <div data-aos="fade-right">
                        <div class="date">{{ el.date }}</div>
                        <div class="title">{{ el.title.toUpperCase() }}</div>
                        <div class="descr">
                            {{ el.description }}
                            <p class="mt-2">
                               <strong>Skills:</strong>
                            </p>
                            <div v-for="(skill, id2) in el.skills" :key="id2" class="ps-4">
                                - <span>{{ skill }}</span>
                            </div>
                        </div>
                    </div>
                </li>
            </ul>
        </div>

        <!-- 3MARK-GPT -->
        <h2 class="primary-color text-center text-md-start fw-bold mt-5 pt-5">3MARK GPT</h2>
        <div class="row" data-aos="fade-right">
            <div class="col-12 mb-5">
                <div data-aos="fade-right">
                    <span class="secondary-color fs-3 fs-lg-4 d-block text-center text-md-start">Try It !</span>
                    <p class="text-white text-center fs-5 mt-4">Using the <strong class="third-color">openAi's API</strong> I also created my personal <strong class="third-color">GPT</strong>.</p>
                    <p class="text-white fs-5 mt-0 text-center">Below the documentation to use it: <a href="https://platform.openai.com/docs/overview" target="_blank" class="link-style-none"><strong class="third-color word-wrap">platform.openai.com/docs/overview</strong></a>.</p>
                    <p class="text-white fs-5 mt-5 text-center">To try it. <strong class="third-color"><i class="fa-solid fa-arrow-turn-down fs-3"></i></strong></p>
                </div>
                <div class="row" data-aos="fade-down">
                    <div class="col-12 my-5 d-flex justify-content-center align-items-center">
                        <div class="img-gpt-logo-container mx-5 cursor-pointer" @click="goToGptPage()">
                            <img src="../../src/assets/logo.webp">
                        </div>
                    </div>
                </div>
            </div>

        </div>

        <!-- MODALI -->
        <div class="modal fade" id="detailModal" tabindex="-1" aria-labelledby="detailModalLabel" aria-hidden="true" v-if="screenOk">
        <!-- Modal Info Obj-->
            <div class="modal-dialog modal-xl">
                <div class="modal-content bg-dark text-white">
                    <div class="card-header d-flex justify-content-between alingn-items-center">
                        <div class="d-flex align-items-center icon-modal">
                            <i class="icon-skill" :class="objModal.classes" v-if="objModal && objModal.type=='icon'"></i>
    
                            <img :src="objModal.src" :class="objModal.classes" :alt="objModal.name" v-else-if="objModal && objModal.type=='img'">
    
                            <h5 class="modal-title" id="detailModalLabel" v-if="objModal">{{objModal.name}}</h5>
                        </div>
                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div class="card-body d-flex flex-column" v-if="objModal">
                        <p>{{objModal.info}}</p>
                        <a :href="objModal.link" class="btn-neon text-center cursor-pointer" target="_blank">READ MORE</a>
                    </div>
                </div>
            </div>
        </div>
    
        <div ref="GFModalLabel" class="modal fade" id="GiuliaModal" tabindex="-1" aria-labelledby="GFModalLabel" aria-hidden="true">
        <!-- Modal GF-->
            <div class="modal-dialog modal-lg">
                <div class="modal-content bg-dark text-white">
                    <div class="card-header d-flex justify-content-between">
                        <div class="d-flex align-items-center">
                            <h5>HEY, YOU FOUND AN EASTER EGG!</h5>
                        </div>
                        <i data-bs-dismiss="modal" aria-label="Close" class="fa-solid fa-xmark text-white fs-4 cursor-pointer"></i>
                    </div>
                    <div class="card-body row">
                        <div class=" col-12 col-lg-4">
                            <label class="label text-white fw-bold mb-3">Password</label>
                            <input type="password" class="form-control py-2" v-model="bindGE.password" @keyup="confirmPasswordOk()">
                        </div>
                        <div class=" col-12 col-lg-8 d-flex flex-column justify-content-end">
                            <button v-if="bindGE.passwordStatus != 1" class="btn-neon text-center w-100 rounded py-2" :class="{'notOk': bindGE.passwordStatus == 0, 'maybe': bindGE.passwordStatus == 2}"><i class="fas fa-lock"></i></button>
    
                            <button @click="goToGFpage()" data-bs-dismiss="modal" aria-label="Close" v-else class="btn-neon text-center w-100 rounded py-2"><i class="fas fa-lock-open"></i></button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>

</template>

<script>
export default {
    name: 'SectionTwo',
    data(){
        return{
            // VAR
            objModal: null,
            active: false,
            screenOk: false,

            // OBJ
            bindGE: {
                password: '',
                passwordStatus: 0
            },

            // ARRAY
            frontEndList: [
                {
                    name: 'html',
                    link: 'https://it.wikipedia.org/wiki/HTML',
                    src: null,
                    classes: 'fab fa-html5 html-item',
                    info: 'Is the standard markup language for documents designed to be displayed in a web browser.',
                    type: 'icon'
                },
                {
                    name: 'css',
                    link: 'https://it.wikipedia.org/wiki/CSS',
                    src: null,
                    classes: 'fab fa-css3-alt css-item',
                    info: 'Is a style sheet language used for describing the presentation of a document written in a markup language such as HTML or XML',
                    type: 'icon'
                },
                {
                    name: 'JavaScript',
                    link: 'https://it.wikipedia.org/wiki/JavaScript',
                    src: null,
                    classes: 'fab fa-js js-item',
                    info: 'Is a high-level\'s programming language and has dynamic typing, prototype-based object-orientation and first-class functions.',
                    type: 'icon'
                },
                {
                    name: 'TypeScript',
                    link: 'https://it.wikipedia.org/wiki/TypeScript',
                    src: require('../assets/typescriptlang-icon.svg'),
                    classes: 'TypeScript',
                    info: 'Is a superset of JS. It adds additional syntax to JS to support a tighter integration with your editor.',
                    type: 'img'
                },
                {
                    name: 'Bootstrap',
                    link: 'https://it.wikipedia.org/wiki/Bootstrap_(framework)',
                    src: null,
                    classes: 'fab fa-bootstrap bootstrap-item',
                    info: 'Is a CSS framework. It contains HTML, CSS and JS based design templates for forms, navigation, and other interface components.',
                    type: 'icon'
                },
                {
                    name: 'Sass-Scss',
                    link: 'https://it.wikipedia.org/wiki/Sass',
                    src: null,
                    classes: 'fab fa-sass sass-item',
                    info: 'Is a preprocessor scripting language that is interpreted or compiled into Cascading Style Sheets (CSS)',
                    type: 'icon'
                },
                {
                    name: 'Vue.Js',
                    link: 'https://it.wikipedia.org/wiki/Vue.js',
                    src: require('../assets/vuejs-icon.svg'),
                    classes: 'VueJs',
                    info: 'Is a JavaScript framework for building user interfaces (UIs) and single-page applications (SPAs).',
                    type: 'img'
                },
                {
                    name: 'Angular',
                    link: 'https://it.wikipedia.org/wiki/Angular',
                    src: null,
                    classes: 'fa-brands fa-angular angular-item',
                    info: 'Is a modern MVVC framework and platform that is used to build enterprise SPAs using HTML and TypeScript.',
                    type: 'icon'
                },
            ],
            backEndList: [
                {
                    name: 'Git',
                    link: 'https://it.wikipedia.org/wiki/Git_(software)',
                    src: null,
                    classes: 'fab fa-git-alt git-item',
                    info: 'Is a software for distributed version control: used for coordinating work among programmers developing source code.',
                    type: 'icon'
                },
                {
                    name: 'Java',
                    link: 'https://it.wikipedia.org/wiki/Java_(linguaggio_di_programmazione)',
                    src: require('../assets/java-svgrepo-com.svg'),
                    classes: 'Java',
                    info: 'Is a high-level, class-based, object-oriented programming language that is designed to have as few implementation dependencies as possible.',
                    type: 'img'
                },
                {
                    name: 'Spring Boot',
                    link: 'https://it.wikipedia.org/wiki/Spring_Framework',
                    src: require('../assets/icons8-spring-boot-100.svg'),
                    classes: 'SpringBoot',
                    info: 'Is an application framework and inversion of control container for the Java platform..',
                    type: 'img'
                },
                {
                    name: 'PHP',
                    link: 'https://it.wikipedia.org/wiki/PHP',
                    src: null,
                    classes: 'fab fa-php php-item',
                    info: 'Is a open source scripting language, scripts are executed on the server',
                    type: 'icon'
                },
                {
                    name: 'Laravel',
                    link: 'https://it.wikipedia.org/wiki/Laravel',
                    src: null,
                    classes: 'fab fa-laravel laravel-item',
                    info: 'Laravel is an PHP open-source framework used for programming web applications.',
                    type: 'icon'
                },
                {
                    name: 'Node.js',
                    link: 'https://en.wikipedia.org/wiki/Node.js',
                    src: null,
                    classes: 'fab fa-node-js node-js-item',
                    info: 'Is an open source development platform for executing JavaScript code server-side.',
                    type: 'icon'
                },
                {
                    name: 'MySql',
                    link: 'https://it.wikipedia.org/wiki/MySQL',
                    src: null,
                    classes: 'fas fa-database sql-item',
                    info: 'Is a relational database management system (RDBMS) developed by Oracle that is based on structured query language (SQL).',
                    type: 'icon'
                },
                {
                    name: 'Mongo DB',
                    link: 'https://it.wikipedia.org/wiki/Spring_Framework',
                    src: require('../assets/mongodb-svgrepo-com.svg'),
                    classes: 'MongoDb',
                    info: 'Is a source-available, cross-platform, document-oriented database program. Classified as a NoSQL database product, MongoDB utilizes JSON-like documents with optional schemas.',
                    type: 'img'
                },
            ],
            workTimelineList: [
                {
                    title: "Technology Advising",
                    date: "2022 - Ongoing",
                    description: "Company specialized in the design and implementation of land management systems aimed at ensuring safety from environmental and anthropic risks; within the system integrator; in the creation and management of software and hardware to assist the activities of public administrations, port authorities, local health authorities, civil protection, banking and insurance institutions and telecommunications companies",
                    skills: ["Angular", "Typescript", "Java", "Spring Boot", "Mongo DB", "Jira for managing weekly tasks"]
                },
                {
                    title: "Boolean Careers",
                    date: "2021 - 2022",
                    description: "Full-time intensive course on Front-End technologies e Back-End with daily exercises with increasing difficulty in which replicate real projects from the world of work",
                    skills: ["JavaScript", "Vue JS", "PHP", "Laravel", "MySql", "GIT", "HTML", "CSS", "Sass/Scss", "Bootstrap"]
                },
                {
                    title: "University of Salerno",
                    date: "2017 - 2018",
                    description: "Is an Italian State university founded in 1968 with headquarters in Fisciano - Salerno. It is one of the best Italian universities in computer science",
                    skills: ["Computer Architecture"]
                },
            ]
        }
    },
    created() {
        this.validScreenForModal();
    },
    methods: {
        showInfo(){
            this.active = !this.active;
        },

        removeInfo(){
            this.active = false;
        },

        validScreenForModal(){
            screen.width<=991? this.screenOk=true : this.screenOk=false;
        },

        setObjModal(obj){
            this.objModal = obj;
        },

        confirmPasswordOk(){
            if(this.bindGE.password!='300421' && this.bindGE.password==''){
                this.bindGE.passwordStatus = 0;
            } else if(this.bindGE.password!='300421' && this.bindGE.password!=''){
                this.bindGE.passwordStatus = 2;
            } else if(this.bindGE.password=='300421'){
                this.bindGE.passwordStatus = 1;
            }
        },

        goToGFpage(){
            this.$router.push({name: 'Giulia'});
        },

        goToGptPage(){
            this.$router.push({name: 'gpt-section'});
        },
    }
}
</script>

<style scoped lang="scss">
    @import '@/style/utilities';
    
    #sectionTwo{

        .img-gpt-logo-container{
            width: 120px;
            height: 120px;
            overflow: hidden;
            border-radius: 150px;
            align-self: center;
            margin-bottom: 10px;
            display: flex;
            align-items: center;
            justify-content: center;
            transition: all .2s ease-in-out;

            &:hover{
                outline: 3px solid #f28343;
                outline-offset: 5px;
            }

            img{
                width: 100%;
                object-fit: cover;
            }
        }
        .my-card{
            border-radius: 6px;
            height: 17rem;
            background-color: #0f0f12a1;
            overflow: hidden;
            .info{
                height: 0;
                overflow: hidden;
                transition: .3s ease-in;
                cursor: url("../assets/pizzaCurs-2.png"), pointer;
                background-color: rgb(34, 34, 34);
                color: #fff;
                .arrow{
                    animation: upAndDown 1.5s infinite ease;
                    @keyframes upAndDown{
                        0%{
                            transform: translateY(2px);
                        }
                        50%{
                            transform: translateY(10px);
                        }
                        100%{
                            transform: translateY(2px);
                        }
                    }
                }
                a{
                    color: #00ff00;
                    text-decoration: none;
                    font-weight: bold;
                    transition: all .2s ease-in;
                    &:hover{
                        transform: scale(1.3);
                        text-shadow:0 0 7px currentColor,
                        
                    }
                }
            }
            .icon-card{

                .icon-skill{
                        font-size: 100px;
                        transition: .3s ease-in;
                        &.html-item{
                            color: #e96228;
                        }
                        &.css-item{
                            color: #2965f1;
                        }
                        &.js-item{
                            color: #f7e018;
                        }
                        &.node-js-item{
                            color: #7fc728;
                        }
                        &.bootstrap-item{
                            color: #830afa;
                        }
                        &.php-item{
                            color: #787cb4;
                        }
                        &.git-item{
                            color: #f05030;
                        }
                        &.sass-item{
                            color: #c66394;
                        }
                        &.laravel-item{
                            color: #f72c1f;
                        }
                        &.sql-item{
                            background: rgb(58,112,180);
                            background: linear-gradient(90deg, rgba(58,112,180,1) 23%, rgba(242,150,0,1) 100%);
                            background-clip: text;
                            color: transparent;
                        }
                        &.angular-item{
                            background: rgb(219,43,45);
                            background: linear-gradient(90deg, rgba(219,43,45,1) 32%, rgba(126,23,25,1) 51%);
                            background-clip: text;
                            color: transparent;
                        }
                    }
                    img{
                        height: 33%;
                        display: block;
                        transition: all .3s ease-in;
                    }
            }

            .info{
                .info-paragraph{
                    overflow: auto;
                    display: flex;
                    flex-direction: column;
                    scrollbar-width: none;
                    &::-webkit-scrollbar {
                        display: none;
                    }
                    & > div{
                        max-height: 90%;
                        overflow: auto;
                        scrollbar-width: none;
                        &::-webkit-scrollbar {
                            display: none;
                        }
                    }
                    a{
                        margin-top: 20px;
                        align-self: center;
                    }
                }
            }

            &:hover{

                .info{
                    height: 60px;
                    &.active{
                        height: 100%;
                        padding: 10px;
                        .arrow{
                            transform: rotate(30deg);
                        }
                    }
                }
                .info-tablet{
                    height: 45px;
                }
                .icon-card{
                    .icon-skill{
                        font-size: 150px;
                        text-shadow: 0 0 10px currentColor;
                        &.sql-item{
                            filter: drop-shadow(3px 1px 5px rgba(58,112,180,1) ) drop-shadow(2px 1px 2px rgba(242,150,0,1));
                        }
                        &.angular-item{
                            filter: drop-shadow(1px 1px 10px rgba(126,23,25,1));
                        }
                    }
                    img{
                        height: 50%;
                        &.TypeScript{
                            box-shadow: 0 0 15px #007acc;
                        }
                        &.VueJs{
                            filter: drop-shadow(5px 0 10px #42b883);
                        }
                        &.Java{
                            filter: drop-shadow(5px 0 10px #73a1fb);
                        }
                        &.SpringBoot{
                            filter: drop-shadow(5px 0 10px #5fb92e);
                        }
                        &.MongoDb{
                            filter: drop-shadow(5px 0 10px #13aa52);
                        }
                        &.Angular{
                            filter: drop-shadow(5px 0 10px #e23237);
                        }
                    }

                }
            }
        }
        .icon-modal{

            .icon-skill{
                        font-size: 35px;
                        margin-right: 10px;
                        // text-shadow: 0 0 7px currentColor;
                        &.html-item{
                            color: #e96228;
                        }
                        &.css-item{
                            color: #2965f1;
                        }
                        &.js-item{
                            color: #f7e018;
                        }
                        &.node-js-item{
                            color: #7fc728;
                        }
                        &.bootstrap-item{
                            color: #830afa;
                        }
                        &.php-item{
                            color: #787cb4;
                        }
                        &.git-item{
                            color: #f05030;
                        }
                        &.sass-item{
                            color: #c66394;
                        }
                        &.laravel-item{
                            color: #f72c1f;
                        }
                        &.sql-item{
                            background: rgb(58,112,180);
                            background: linear-gradient(90deg, rgba(58,112,180,1) 23%, rgba(242,150,0,1) 100%);
                            background-clip: text;
                            color: transparent;
                            // filter: drop-shadow(3px 1px 5px rgba(58,112,180,1) ) drop-shadow(2px 1px 2px rgba(242,150,0,1));
                        }
                        &.angular-item{
                            background: rgb(219,43,45);
                            background: linear-gradient(90deg, rgba(219,43,45,1) 32%, rgba(126,23,25,1) 51%);
                            background-clip: text;
                            color: transparent;
                            // filter: drop-shadow(1px 1px 10px rgba(126,23,25,1));
                        }
            }
            img{
                width: 15%;
                margin-right: 10px;
                
            }
        }
        /**********
            RESPONSIVE 
        ***********/
        @media screen and (max-width: 400px) {
            .my-card{
                height: 10rem;
                .icon-card{
                    .icon-skill{
                        font-size: 55px;
                    }
                }
                &:hover{
                    .icon-card{
                        .icon-skill{
                            font-size: 80px;
                            text-shadow:
                            0 0 7px currentColor,
                        }

                    }
                }
            }
        }

        @media screen and (min-width: 401px) and (max-width: 991px) {
            .my-card{
                height: 15rem;
                .icon-card {

                    img{
                        height: 38%;
                    }
                    cursor: url("../assets/pizzaCurs-2.png"), pointer;
                }
                &:hover{
                    .icon-card {

                        img{
                            height: 55%;
                        }
                    }
                }
            }
        }
    }
    
    #workTimeline{

    
    ul {
      --col-gap: 2rem;
      --row-gap: 30rem;
      --line-w: 0.25rem;
      display: grid;
      grid-template-columns: var(--line-w) 1fr;
      grid-auto-columns: max-content;
      column-gap: var(--col-gap);
      list-style: none;
      width: min(60rem, 90%);
      margin-inline: auto;
    }
    
    /* line */
    ul::before {
      content: "";
      grid-column: 1;
      grid-row: 1 / span 20;
      background: #00ff00;
      border-radius: calc(var(--line-w) / 2);
    }
    
    /* columns*/
    
    /* row gaps */
    ul li:not(:last-child) {
      margin-bottom: var(--row-gap);
    }
    
    /* card */
    ul li {
      color: #fff;
      grid-column: 2;
      --inlineP: 1.5rem;
      --accent-color: #f28343;
      margin-inline: var(--inlineP);
      grid-row: span 2;
      display: grid;
      grid-template-rows: min-content min-content min-content;
    }
    
    /* date */
    ul li .date {
      --dateH: 3rem;
      height: var(--dateH);
      margin-inline: calc(var(--inlineP) * -1);
    
      text-align: center;
      background-color: var(--accent-color);
    
      color: white;
      font-size: 1.25rem;
      font-weight: 700;
    
      display: grid;
      place-content: center;
      position: relative;
    
      border-radius: calc(var(--dateH) / 2) 0 0 calc(var(--dateH) / 2);
    }
    
    /* date flap */
    ul li .date::before {
      content: "";
      width: var(--inlineP);
      aspect-ratio: 1;
      background: var(--accent-color);
      background-image: linear-gradient(rgba(0, 0, 0, 0.2) 100%, transparent);
      position: absolute;
      top: 100%;
    
      clip-path: polygon(0 0, 100% 0, 0 100%);
      right: 0;
    }
    
    /* circle */
    ul li .date::after {
      content: "";
      position: absolute;
      width: 2rem;
      aspect-ratio: 1;
      background: #f28343b2;
      border: 0.3rem solid var(--accent-color);
      border-radius: 50%;
      top: 50%;
    
      transform: translate(50%, -50%);
      right: calc(100% + var(--col-gap) + var(--line-w) / 2);
    }
    
    /* title descr */
    ul li .title,
    ul li .descr {
      background: var(--bgColor);
      position: relative;
      padding-inline: 1.5rem;
    }
    ul li .title {
      overflow: hidden;
      padding-block-start: 1.5rem;
      padding-block-end: 1rem;
      font-weight: 500;
    }
    ul li .descr {
      padding-block-end: 1.5rem;
      font-weight: 300;
    }
    
    /* shadows */
   
    ul li .title::before {
      bottom: calc(100% + 0.125rem);
    }
    
    ul li .descr::before {
      z-index: -1;
      bottom: 0.25rem;
    }
    
    @media (min-width: 40rem) {
      ul {
        grid-template-columns: 1fr var(--line-w) 1fr;
      }
      ul::before {
        grid-column: 2;
      }
      ul li:nth-child(odd) {
        grid-column: 1;
      }
      ul li:nth-child(even) {
        grid-column: 3;
      }
    
      /* start second card */
      ul li:nth-child(2) {
        grid-row: 2/4;
      }
    
      ul li:nth-child(odd) .date::before {
        clip-path: polygon(0 0, 100% 0, 100% 100%);
        left: 0;
      }
    
      ul li:nth-child(odd) .date::after {
        transform: translate(-50%, -50%);
        left: calc(100% + var(--col-gap) + var(--line-w) / 2);
      }
      ul li:nth-child(odd) .date {
        border-radius: 0 calc(var(--dateH) / 2) calc(var(--dateH) / 2) 0;
      }
    }
    
 
  

    }
</style>